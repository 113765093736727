import React, { useRef, useState } from "react";
import { Link } from "@reach/router";
import SourceBox from "./SourceBox";
import OutputBox from "./OutputBox";
import ToolsBreadcrumb from "components/ToolsBredcrumb";

const AnonymizationTool = () => {
  const [sourceText, setSourceText] = useState("");
  const [outputText, setOutputText] = useState("");
  const textareaRef = useRef(null);
  const outputRef = useRef(null);

  return (
    <div>
      <div className="w-full py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="max-w-7xl mx-auto px-4 lg:pt-[60px] lg:pb-[60px] pt-12 pb-8 ">
        <h1 className="text-[#5B93FF] font-primary text-base uppercase text-center font-semibold mb-2.5">
          Data Anonymization Tool
        </h1>
        <h2 className="text-[28px]  md:text-3xl leading-[48px]  text-center font-primary  font-bold ">
          Safeguard Sensitive Information with Automated Redaction
        </h2>
        <p className="mt-6 text-lg text-center font-opensans">
          Upload your dataset, and the tool will automatically remove or encrypt
          sensitive information to protect privacy and comply with regulations.
          This ensures your data remains secure and anonymous while maintaining
          its usability for analysis or processing.
        </p>
        <div className="mt-12 flex lg:flex-row flex-col gap-4">
          <SourceBox
            sourceText={sourceText}
            setSourceText={setSourceText}
            outputText={outputText}
            setOutputText={setOutputText}
            textareaRef={textareaRef}
            outputRef={outputRef}
          />
          <OutputBox outputText={outputText} outputRef={outputRef} />
        </div>
        <p className="w-full text-center mt-12 text-base text-[#9C9C9C]  mb-4 ">
          Assisted by AI tools. Inaccuracies may occur.
        </p>
      </div>
    </div>
  );
};

export default AnonymizationTool;
